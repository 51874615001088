<template>
  <v-card-text>
    <v-form ref="socialNameForm" v-model="form.valid" lazy-validation>
      <v-row class="px-3">
        <d-select
          v-model="form.gender"
          @change="handleGenderSelectChange"
          :items="genderList"
          :rules="[isEmpty]"
          hide-details="auto"
          label="Genero"
          placeholder="Escolha o seu Genero"
          class="d-select"
        ></d-select>
      </v-row>
      <v-row class="px-3 pt-4" v-if="showSocialNameInput">
        <d-text-field
          v-model="form.socialName"
          :rules="[isEmpty, isBlank, isValidText, doesNotContainNumbers, isFullName]"
          append-icon="mdi-information"
          label="Nome Social (opcional)"
          placeholder="Digite o seu Nome Social"
          class="d-text-field"
          @keyup="removeUndesiredValues"
          @click:append="help"
        ></d-text-field>
        <p class="disclaimer__social_name">
          O nome social é um direito garantido por lei <strong>(Decreto nº 8.727/2016)</strong> e
          uma forma de reconhecimento da identidade de gênero de pessoas transexuais, travestis ou
          não-binárias. É essencial que os órgãos e instituições públicas garantam o direito ao nome
          social para combater a discriminação e preconceito, além de assegurar a inclusão de
          pessoas trans e não-binárias nas instituições de ensino
          <strong>(Lei nº 9.394/1996)</strong>.
        </p>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
  import { formFields } from '@/utils/mixins'

  export default {
    name: 'UploadDocumentsSocialNameForm',
    mixins: [formFields],
    props: {
      help: {
        type: Function,
        required: false,
        default: () => ({})
      },
      agreeSocialName: {
        type: Boolean,
        required: false,
        default: null
      }
    },
    data: () => ({
      showSocialNameInput: false,
      form: {
        valid: false,
        socialName: null,
        gender: null,
        socialNameConfirmed: true
      },
      genderList: ['Mulher Trans', 'Homem Trans', 'Não Especificado']
    }),
    watch: {
      agreeSocialName: {
        handler(value) {
          if (!value) {
            this.form = {
              valid: false,
              socialName: null,
              gender: null,
              socialNameConfirmed: true
            }
          }
        }
      }
    },
    methods: {
      handleGenderSelectChange(gender) {
        if (gender.includes('Cis')) {
          this.showSocialNameInput = false
          this.form.socialName = null
          this.form.socialNameConfirmed = false
        } else {
          this.showSocialNameInput = true
          this.form.socialNameConfirmed = true
        }
      },
      reset() {
        const socialNameReset = this.$refs.socialNameForm
        socialNameReset.reset()
      },
      resetValidation() {
        const socialNameResetVal = this.$refs.socialNameForm
        socialNameResetVal.resetValidation()
      },
      handleFormData() {
        const form = { ...this.form }
        form.valid = this.$refs.socialNameForm.validate()
        return form
      },
      validate() {
        return { ...this.handleFormData() }
      },
      removeUndesiredValues() {
        this.form.socialName = this.form.socialName.replace(
          /[\d!@#$%¨&*;?/(){};:<>,\][ªº+-=\\|'"`´~^¹²³£¢¬]/g,
          ''
        )
      }
    }
  }
</script>

<style scoped>
  .disclaimer__social_name {
    color: #909090;
    text-align: justify;
  }

  .d-select {
    background-color: #ffffff !important;
  }
  .d-select::v-deep .v-text-field__details,
  .d-text-field::v-deep .v-text-field__details {
    background-color: #f5f5f5 !important;
    margin: 0 !important;
    padding-top: 8px !important;
  }
  .d-select::v-deep .v-input__slot {
    margin: 0;
  }

  .d-text-field {
    background-color: #ffffff !important;
  }
  .d-text-field::v-deep .v-input__slot {
    margin: 0;
  }

  .d-text-field::v-deep .v-messages {
    padding-bottom: 16px;
  }
</style>
