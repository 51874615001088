<template>
  <div class="fill-height grey lighten-4">
    <v-card-text>
      <v-list color="transparent" v-if="userData" class="grid">
        <v-list-item
          v-for="({ key, value, icon, socialName }, index) in userData"
          :key="index"
          class="box"
          :class="{ 'd-flex flex-column align-start': socialName }"
          style="align-self: start"
        >
          <div
            style="width: 100%"
            class="d-flex"
            :class="{ 'grey lighten-3 rounded border': socialName, changed: isSocialNameChanged }"
          >
            <v-icon color="primary" v-text="icon" class="px-2" />
            <v-list-item-content>
              <v-list-item-title class="px-2 d-flex align-center break-line">
                <span style="text-wrap: nowrap" class="mr-1">{{ key }}:</span>
                <div class="d-flex justify-space-between align-center" style="width: 100%">
                  <strong :class="{ 'social-name': socialName, changed: isSocialNameChanged }">{{
                    value
                  }}</strong>
                  <v-icon right v-if="socialName && !isSocialNameChanged" color="error"
                    >mdi-alert-circle</v-icon
                  >
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </div>
          <div v-if="socialName" class="d-flex justify-end" style="align-self: end">
            <v-btn
              small
              outlined
              color="primary"
              style="border: none; text-transform: capitalize"
              @click="emitEditClick"
            >
              <v-icon small>mdi-square-edit-outline</v-icon>
              Editar
            </v-btn>
            <v-btn
              small
              outlined
              color="error"
              style="border: none; text-transform: capitalize"
              @click="emitRemoveclick"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
              Remover
            </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </div>
</template>

<script>
  import { formFields } from '@/utils/mixins'

  export default {
    name: 'UploadDocumentsUserData',
    mixins: [formFields],
    props: {
      isReprovedBySocialName: {
        type: Boolean,
        required: true
      }
    },
    components: {},
    methods: {
      emitEditClick() {
        this.$emit('socialName:edit')
      },
      emitRemoveclick() {
        this.$emit('socialName:remove', {
          socialName: null,
          gender: null,
          socialNameConfirmed: false
        })

        this.$forceUpdate()
      }
    },
    data() {
      return {
        isSocialNameChanged: false
      }
    },

    computed: {
      user() {
        return this.$store.state.eligibility.user
      },
      userData() {
        const items = [
          {
            key: 'Nome',
            value: this.user.name,
            icon: 'mdi-account'
          },
          {
            key: 'RG',
            value: this.$options.filters.rgApplyMask(this.user.rg),
            icon: 'mdi-badge-account-horizontal-outline'
          },
          {
            key: 'Curso',
            value: this.user.course.name,
            icon: 'mdi-school'
          },
          {
            key: 'CPF',
            value: this.$options.filters.cpfApplyMask(this.user.cpf),
            icon: 'mdi-badge-account-horizontal'
          },
          {
            key: 'Nascimento',
            value: this.user.birthdate
              ? this.formatStringDate(this.user.birthdate)
              : this.user.dateOfBirth,
            icon: 'mdi-calendar'
          },
          {
            key: 'Instituição',
            value: this.user.institution.name,
            icon: 'mdi-bank'
          }
        ]

        !this.user.isAdult &&
          items.splice(3, 0, {
            key: 'Responsável',
            value: this.user.legalGuardian,
            icon: 'mdi-account-supervisor'
          })

        this.user.socialName &&
          this.isReprovedBySocialName &&
          items.splice(6, 0, {
            key: 'Nome Social',
            value: this.user.socialName,
            icon: 'mdi-account-outline',
            socialName: true
          })

        return items
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/main.scss';

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .v-list-item .border {
    border: solid 1px $error !important;
  }
  .v-list-item .border.changed {
    border: solid 1px $primary !important;
  }
  .social-name {
    color: $error;
  }

  .social-name.changed {
    color: $primary;
  }

  .v-list-item::after {
    content: none;
  }

  @media screen and (min-width: 445px) and (max-width: 845px) {
    .v-list-item__title {
      display: unset !important;
    }
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .box:nth-child(4) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    .box:nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .box:nth-child(6) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    .box:nth-child(7) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
    }
    .box:nth-child(8) {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
  @media (max-width: 445px) {
    .v-list-item__title {
      display: unset !important;
    }
    .grid {
      grid-template-columns: 1fr;
    }
    .box:nth-child(7) {
      grid-row-start: 8;
      grid-row-end: 9;
    }
  }
</style>
