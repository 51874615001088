<template>
  <v-card outlined>
    <div class="fill-height grey lighten-4">
      <v-card-text class="py-8 text-center">
        <v-tabs v-model="selectedTab" background-color="transparent" centered>
          <v-tab
            v-for="({ text, files }, index) in documentOptions"
            v-text="text"
            :key="index"
            @click="selectedDocument = files"
          ></v-tab>
        </v-tabs>
        <v-form v-model="form.valid" ref="uploadDocumentsIDForm" lazy-validation>
          <v-row>
            <v-col v-for="(file, index) in selectedDocument" :key="index">
              <div class="mt-8">
                <UploadDocumentsIDFile :file="file.url" outlined color="white" class="pa-4" />
              </div>
              <d-btn class="mt-4" outlined @click.stop="openInputFile(file)">
                <v-icon left dark>mdi-upload</v-icon>
                {{ file.btn }}
              </d-btn>
            </v-col>
          </v-row>
          <input
            type="file"
            class="d-none"
            ref="uploadDocumentsIDInputFile"
            accept="image/png, image/jpeg"
            @change="changeInputFile"
          />
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { handleFiles } from '@/utils/mixins'
import UploadDocumentsIDFile from './UploadDocumentsIDFile'

export default {
  name: 'UploadDocumentsID',
  mixins: [handleFiles],
  components: { UploadDocumentsIDFile },
  props: {
    front: {
      type: String,
      require: false,
      default: null
    },
    back: {
      type: String,
      require: false,
      default: null
    },
    description: {
      type: String,
      require: false,
      default: null
    }
  },
  watch: {
    front: {
      handler(url) {
        this.loadDocuments(this.getTabByText)
        this.selectedDocument[0].url = url
      }
    },
    back: {
      handler(url) {
        this.selectedDocument[1].url = url
      }
    }
  },
  data: () => ({
    form: {
      valid: false
    },
    selectedTab: null,
    selectedFile: {},
    selectedDocument: {},
    documentOptions: [{
      text: 'RG',
      files: [{
        description: 'RG',
        type: 'IDENTITY_DOCUMENT_FRONT',
        url: '/img/modelo-de-documento-rg-frente.jpg',
        btn: 'Enviar frente do RG'
      }, {
        description: 'RG',
        type: 'IDENTITY_DOCUMENT_BACK',
        url: '/img/modelo-de-documento-rg-verso.jpg',
        btn: 'Enviar verso do RG'
      }]
    }, {
      text: 'CNH',
      files: [{
        description: 'CNH',
        type: 'IDENTITY_DOCUMENT_FRONT',
        url: '/img/modelo-de-documento-cnh.jpg',
        btn: 'Enviar CNH'
      }]
    }, {
      text: 'RNE/CRNM',
      files: [{
        description: 'RNE',
        type: 'IDENTITY_DOCUMENT_FRONT',
        url: '/img/modelo-de-documento-rne.jpg',
        btn: 'Enviar RNE'
      }]
    }]
  }),
  computed: {
    inputFile() {
      return this.$refs.uploadDocumentsIDInputFile
    },
    getTabByText() {
      return this.documentOptions.findIndex(item => item.text === this.description)
    }
  },
  mounted() {
    this.loadDocuments()
  },
  methods: {
    loadDocuments (tab) {
      this.selectedTab = tab || 0
      const { files } = this.documentOptions[this.selectedTab]
      this.selectedDocument = files
    },
    openInputFile(file) {
      this.selectedFile = file
      this.inputFile.value = null
      this.inputFile.click()
    },
    async changeInputFile(event) {
      const files = event.target.files || event.dataTransfer.files
      try {
        const baseUrl = await this.handleFiles(files)
        fetch(baseUrl)
          .then(response => response.blob())
          .then(blob => this.handleFormData(blob))
      }
      catch (error) { this.$store.dispatch('notifier/notify', { error }) }
    },
    handleFormData(blob) {
      const formData = new FormData()
      const { type, description } = this.selectedFile
      const file = new File([blob], `${description.toLowerCase()}.jpeg`, { type: 'image/jpeg' })

      formData.append('type', type)
      formData.append('description', description)
      formData.append('file', file, `${description.toLowerCase()}.jpeg`)

      this.$emit('upload:document', formData)
    }
  }
}
</script>
