<template>
  <div class="py-4 px-8">
    <div class="d-flex">
      <v-badge
        color="success"
        offset-x="11"
        overlap
      >
        <template #badge>
          <v-icon class="white--text" size="12">mdi-check</v-icon>
        </template>
        <v-img
          loading="lazy"
          width="80"
          height="80"
          alt="Modelo de selfie correto"
          :src="require('@/assets/images/modelo-de-selfie-correto.png')"
        ></v-img>
      </v-badge>
      <div class="pl-8 mt-n3">
        <h4 class="font-weight-medium">Como fazer:</h4>
        <ul class="body-2">
          <li>Foto de frente e centralizada;</li>
          <li>Fundo neutro e simples;</li>
          <li>Rosto em primeiro plano sem corte;</li>
          <li>Para mais detalhes <a href="https://bilheteunico.sptrans.com.br/comoenviar.aspx" target="_blank">clique aqui</a>.</li>
        </ul>
      </div>
    </div>
    <div class="pt-4">
      <h4 class="font-weight-medium">Como não fazer:</h4>
      <v-row class="py-4">
        <v-col cols="4" v-for="({ file, alt }, index) in helpPictures" :key="index" class="text-center">
          <v-badge
            color="error"
            offset-x="11"
            overlap
          >
            <template #badge>
              <v-icon class="white--text" size="12">mdi-close</v-icon>
            </template>
            <v-img
              loading="lazy"
              width="80"
              height="80"
              :src="require(`@/assets/images/${file}`)"
              :alt="alt"
            ></v-img>
          </v-badge>
          <div v-html="alt" class="body-2"></div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDocumentsSelfieExplain',
  data: () => ({
    helpPictures: [{
      file: 'modelo-de-selfie-errado-rosto-coberto.png',
      alt: 'Rosto coberto'
    }, {
      file: 'modelo-de-selfie-errado-lentes-escuras.png',
      alt: 'Lentes escuras'
    }, {
      file: 'modelo-de-selfie-errado-foto-de-perfil.png',
      alt: 'De perfíl'
    }, {
      file: 'modelo-de-selfie-errado-rosto-cortado.png',
      alt: 'Rosto cortado'
    }, {
      file: 'modelo-de-selfie-errado-fundo-complexo.png',
      alt: 'Fundos complexos'
    }, {
      file: 'modelo-de-selfie-errado-baixa-qualidade.png',
      alt: 'Baixa qualidade'
    }]
  })
}
</script>
