<template>
  <div class="d-flex flex-wrap flex-md-nowrap">
    <v-card elevation="0" class="flex-size-50 pa-8">
      <v-card-title></v-card-title>
      <v-card-text class="text-center">
        <v-form v-model="form.valid" ref="uploadDocumentsSelfieForm" lazy-validation>
          <CardPreview v-model="value" />
          <input
            type="file"
            class="d-none"
            ref="uploadDocumentsSelfieInputFile"
            accept="image/png, image/jpeg"
            @change="changeInputFile"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <d-btn outlined @click.stop="openInputFile">
          <v-icon left dark>mdi-upload</v-icon>
          Enviar foto
        </d-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <UploadDocumentsSelfieExplain class="flex-size-50" />
    <d-image-crop-dialog ref="imageCropDialog"></d-image-crop-dialog>
  </div>
</template>

<script>
import { handleFiles } from '@/utils/mixins'
import { CardPreview } from '@/components'
import UploadDocumentsSelfieExplain from './UploadDocumentsSelfieExplain'

export default {
  name: 'UploadDocumentsSelfie',
  mixins: [handleFiles],
  components: {
    CardPreview,
    UploadDocumentsSelfieExplain
  },
  props: {
    value: {
      type: String,
      require: false,
      default: null
    }
  },
  data: () => ({
    form: {
      valid: false
    }
  }),
  computed: {
    inputFile () {
      return this.$refs.uploadDocumentsSelfieInputFile
    }
  },
  methods: {
    openInputFile () {
      this.inputFile.value = null
      this.inputFile.click()
    },
    async changeInputFile (event) {
      const files = event.target.files || event.dataTransfer.files
      const url = await this.handleFiles(files)
      const blob = await this.$refs.imageCropDialog.open(url)
      this.handleFormData(blob)
    },
    handleFormData (blob) {
      const formData = new FormData()
      const file = new File([blob], 'selfie.jpg', { type: 'image/jpeg' })

      formData.append('type', 'SELFIE')
      formData.append('description', 'SELFIE')
      formData.append('file', file, 'selfie.jpeg')

      this.$emit('upload:selfie', formData)
    }
  }
}
</script>
