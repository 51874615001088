<template>
  <div>
    <h2 class="font-weight-regular">Dados Cadastrais</h2>
    <p>Visualize seus dados cadastrais enviados pela sua instituição de ensino.</p>
    <v-card outlined class="mb-4 fill-height grey lighten-4">
      <UploadDocumentsUserData
        ref="UploadDocumentsUserData"
        @socialName:edit="openSocialNameForm"
        @socialName:remove="emitRemoveClick"
        :isReprovedBySocialName="isReprovedBySocialName"
      />
      <div v-if="showSocialNameForm">
        <UploadDocumentsSocialNameFormVue ref="UploadDocumentsSocialNameFormVue" />
        <v-card-text class="d-flex justify-end mt-n5">
          <v-btn class="primary mt-3" style="text-transform: capitalize" @click="agreement"
            >Confirmar</v-btn
          >
        </v-card-text>
      </div>
    </v-card>
    <v-divider class="my-3" />
  </div>
</template>

<script>
  import UploadDocumentsUserData from './UploadDocumentsUserData.vue'
  import UploadDocumentsSocialNameFormVue from './UploadDocumentsSocialNameForm.vue'

  export default {
    name: 'UserData',
    components: {
      UploadDocumentsUserData,
      UploadDocumentsSocialNameFormVue
    },
    props: {
      isReprovedBySocialName: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        showSocialNameForm: false
      }
    },
    methods: {
      openSocialNameForm(e) {
        this.showSocialNameForm = !this.showSocialNameForm
        this.$emit('socialName:edit', e)
      },
      emitRemoveClick(e) {
        this.showSocialNameForm = false
        this.$emit('socialName:remove', e)
      },
      agreement() {
        const form = this.$refs.UploadDocumentsSocialNameFormVue.validate()
        form.valid && this.$emit('socialNameform:confirm', form)
      }
    }
  }
</script>
