<template>
  <div>
    <UserData
      ref="UserData"
      v-if="isConference_nok"
      @socialNameform:confirm="saveSocialNameChange"
      @socialName:remove="deleteSocialName"
      :isReprovedBySocialName="isReprovedBySocialName"
    />
    <h2 class="font-weight-regular">Envio de documentos</h2>
    <p>Envie uma foto sua e, selecione e envie um dos tipos de documento de identificação abaixo</p>
    <UploadDocumentsSelfie v-model="selfie" @upload:selfie="uploadFile" />
    <UploadDocumentsID
      :front.sync="front"
      :back.sync="back"
      :description.sync="dDescription"
      @upload:document="uploadFile"
    />
    <d-btn class="my-8 mr-4" @click="upload">Continuar</d-btn>
  </div>
</template>

<script>
  import { UploadService, FollowUpService } from '@/services'
  import { trackEvent } from '@/utils/mixins'
  import { UploadDocumentsSelfie, UploadDocumentsID, UserData } from './components'

  const _followUpBack = new FollowUpService(window._sptrans.apis.core_back)
  const _upload = new UploadService(window._sptrans.apis.base)
  const _conference = new FollowUpService(window._sptrans.apis.grbox)
  const _followUp = new FollowUpService(window._sptrans.apis.base)

  export default {
    name: 'UploadDocuments',
    mixins: [trackEvent],
    components: {
      UploadDocumentsSelfie,
      UploadDocumentsID,
      UserData
    },
    data: () => ({
      selfie: null,
      front: null,
      back: null,
      dDescription: null,
      order: null,
      conference: null
    }),
    computed: {
      orderId() {
        return this.$store.state.order.orderId
      },
      userUuid() {
        return this.$store.state.eligibility.user.uuid
      },
      uType() {
        return {
          SELFIE: 'selfie',
          IDENTITY_DOCUMENT_FRONT: 'front',
          IDENTITY_DOCUMENT_BACK: 'back'
        }
      },
      isConference_nok() {
        return this.order?.boxMessageFollowUp?.status === 'CONFERENCE_NOK'
      },
      isReprovedBySocialName() {
        return this.conference?.errors?.filter(err => err.code === 'V071').length > 0
      }
    },
    async mounted() {
      try {
        const { data } = await _followUp.getOrder()
        this.order = { ...data }

        if (this.order?.conferenceUuid) {
          const { content } = await _conference.getConference({ uuid: this.order.conferenceUuid })
          this.conference = content
        }
      } catch (error) {
        this.$store.dispatch('notifier/notify', { error })
      }

      const { data } = await _upload.getFiles()

      data.uploads.map(({ type, description, url }) => {
        this.dDescription = description !== 'SELFIE' ? description : null
        const file = this.uType[type]
        this[file] = url
      })

      // Remover esse comentario quando for iniciar o teste A/B (Conferir no inspectlet se o id do experimento é o mesmo)
      // if (/Mobi|Android/i.test(navigator.userAgent)) {
      //   window.__insp.push(['activateExperiment', 747510191])
      // }
    },
    methods: {
      async saveSocialNameChange(e) {
        try {
          const { data } = await _followUpBack.changeSocialName(e)
          this.$refs.UserData.showSocialNameForm = false
          this.$store.dispatch('notifier/notify', {
            status: true,
            message: 'Nome social alterado com sucesso.',
            type: 'success'
          })
          this.handleEditOrRemoveSocialNameSuccess(data)
        } catch (error) {
          this.$store.dispatch('notifier/notify', { error })
        }
      },
      async deleteSocialName(e) {
        try {
          const { data } = await _followUpBack.changeSocialName(e)
          this.$store.dispatch('notifier/notify', {
            status: true,
            message: 'Nome social removido com sucesso.',
            type: 'success'
          })
          this.handleEditOrRemoveSocialNameSuccess(data)
        } catch (error) {
          this.$store.dispatch('notifier/notify', { error })
        }
      },
      handleEditOrRemoveSocialNameSuccess({ student, address, institution, course, cardNumber }) {
        const user = Object.assign(student, {
          address,
          institution,
          course,
          cardNumber
        })

        this.$store.dispatch('eligibility/setUser', { user })

        this.$router.push({ name: 'followUp' })
      },
      async getFiles(status) {
        const { data } = await _upload.getFiles({ status })
        return { ...data }
      },
      async uploadFile(form) {
        const file = form.get('file')
        const type = form.get('type')
        const description = form.get('description')
        const contentType = file.type

        try {
          const { data } = await _upload.getPreSignedUrl({ type, contentType })
          this.uploadToStorage({ file, type, description, contentType, ...data })
        } catch (error) {
          this.$store.dispatch('notifier/notify', { error })
        }
      },
      async uploadToStorage(data) {
        const _storage = new UploadService(data.url, {
          headers: { 'Content-Type': data.contentType }
        })

        try {
          await _storage.sendFile(data.file)
          this.uploadToStorageSuccess(data)
        } catch (error) {
          console.log('UploadDocuments:Failure::', { error })
          this.$store.dispatch('notifier/notify', { error })
        }
      },
      async uploadToStorageSuccess({ type, description }) {
        const { data } = await _upload.sendFileSuccess({ type, description })
        this.dDescription = data.description
        const file = this.uType[data.type]
        this[file] = data.url
      },
      async upload() {
        try {
          const { data } = await _upload.sendUpload()
          const { completed, nextStep } = data
          if (!completed) {
            this.$store.dispatch('notifier/notify', {
              status: true,
              message: 'Você precisa nos enviar seus documentos',
              type: 'error'
            })
          } else {
            this.trackEvent({
              event: 'uploadDocuments',
              params: {
                uuid: this.orderId,
                userToken: this.userUuid,
                tipoDocumentosUploads: this.dDescription
              }
            })
            this.$nextTick(() => {
              this.$router.push({ name: nextStep })
            })
          }
        } catch (error) {
          this.$store.dispatch('notifier/notify', { error })
        }
      }
    }
  }
</script>
