<template>
  <div :class="`
    file-preview
    ${color && 'rounded'}
    ${outlined && 'v-sheet v-sheet--outlined theme--light'}
  `">
    <div :class="`fill-height ${color}`">
      <v-img
        :src="file"
        width="400"
        alt="Documento de identificação"
      ></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadDocumentsIDFile',
  props: {
    file: {
      type: String,
      require: false,
      default: null
    },
    outlined: {
      type: Boolean,
      require: false,
      default: false
    },
    color: {
      type: String,
      require: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.file-preview {
  display: inline-block;
  position: relative
}

@media only screen and (max-width: 768px) {
  .file-preview { width: 100% }
}
</style>
